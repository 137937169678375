<template lang="pug">
head-meta(title-page="Регистрация" )
the-registration
contacts-line
</template>

<script>
import TheRegistration from '../components/login/TheRegistration'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'RegistrationPage',
  components: { ContactsLine, HeadMeta, TheRegistration }
}
</script>

<template lang="pug">
article.login
  .login__cont.cont
    template(v-if="register")
      h1.login__title.title Регистрация
      p.login__text.text-big
        | Создайте личный кабинет, чтобы увидеть заказы,
        br
        | ранее добавленные товары в корзине и сохранять любимые&nbsp;товары
      form.login__form(@submit="sendForm")
        input.login__input.text.input(:class="{ 'input-invalid': errors.email }", type="email", v-model="email", placeholder="Электронная почта*", required=true)
        span.login__info.login__info_error.text(v-if="errors.email") {{ errors.email }}
        input.login__input.text.input(type="password", v-model="pass" minLength="8", name="password", placeholder="Пароль*", required=true)
        span.login__info.text Минимум — 8 символов
        input.login__input.text.input(type="text", v-model="name", placeholder="Имя и фамилия")
        span.login__info.text чтобы мы знали, как к вам обратиться
        input.login__input.text.input(type="text", placeholder="Телефон" v-model="phone")
        span.login__info.text чтобы мы могли быстро связаться с&nbsp;вами
        button.login__button.button-white(type='submit') Зарегистрироваться
        span.login__policy и принять условия&thinsp;
          router-link.login__policy-link(:to="{ name: 'Policy' }") Политики конфиденциальности
      ul.login__list
        li.login__item.login__item_registration
          router-link.login__link.link-dot_white.text(:to="{ name: 'Login' }") Уже есть кабинет? Войти
        li.login_item.login__item_registration
          router-link.login__link.link-dot_white.text(:to="{ name: 'ForgotPassword' }") Забыли пароль?
    template(v-else)
      h1.login__title.title Подтвердите почту
      p.login__text.text-big
        | Отправили письмо на&nbsp;{{ email }}
        br
        | Откройте его и перейдите по ссылке, чтобы завершить регистрацию.
      p.login__text.text-big
        | Если письма нет в ящике, проверьте папку «Спам».
        br
        | Если там тоже нет, напишите на почту
        |
        a.login__mail-link.link_white(href="mail.link") {{mail.mask}}
        br
        | ответим в ближайшее время
</template>

<script>
import '@/assets/styles/components/login.sass'
import { registration } from '../../assets/scripts/API'
import { mail } from '../../assets/scripts/variables'

export default {
  name: 'Registration',
  data () {
    return {
      register: true,
      errors: { },
      email: null,
      pass: null,
      name: null,
      phone: null,
      mail
    }
  },
  methods: {
    async sendForm (event) {
      event.preventDefault()
      const res = await registration(this.email, this.pass, this.name, this.phone)
      window.scrollTo(0, 0)
      if (res.status === 'error') {
        this.errors = res.errors
      } else {
        this.error = false
        this.register = false
      }
    }
  }
}
</script>
